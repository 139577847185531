
import { defineComponent, ref, computed } from "vue";
import { ErrorMessage, Field, Form } from "vee-validate";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import router from "@/router";
import ApiService from "@/core/services/ApiService";
import { useI18n } from 'vue-i18n';

export default defineComponent({
  name: "sign-in",
  components: {
    Field,
    Form,
    ErrorMessage,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const submitButton = ref<HTMLButtonElement | null>(null);
    const { t } = useI18n();

    const currentLanguage = computed(() => store.getters.getCurrentLanguage || localStorage.getItem('currentLanguage'));

    if(store.getters.isUserAuthenticated){
      router.push("/");
    }

    //Create form validation object
    const login = Yup.object().shape({
      email: Yup.string().email().required().label("Email"),
      password: Yup.string().min(4).required().label("Password"),
    });

    //Form submit function
    const onSubmitLogin = async (values) => {
      // Clear existing errors

      if (submitButton.value) {
        // eslint-disable-next-line
        submitButton.value!.disabled = true;
        // Activate indicator
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }

      const params = {
        params: {
          ...values,
        },
      };

      if(process.env.NODE_ENV === "development-dev"){
        return  await ApiService.query("dummy", params)
        .then(({ data }) => {
          store.commit(Mutations.SET_AUTH, data);
          router.push({
            name: "dashboard",
          
          })
        })
        .catch(({ response }) => {

            Swal.fire({
              title: "Oops...",
              text: "Tivemos um problema para realizar o login, tente novamente mais tarde.",
              icon: "error",
            });
                submitButton.value?.removeAttribute("data-kt-indicator");
              // eslint-disable-next-line
              submitButton.value!.disabled = false;
              // eslint-disable-next-line
        });

      }else {
        return await ApiService.post("auth/login", values)
        .then(async ({ data }) => {
         
        

          if(data.success){ 

            await store.commit(Mutations.SET_AUTH, data.user);
          
              router.push({ name: "dashboard" });
              submitButton.value?.removeAttribute("data-kt-indicator");
              // eslint-disable-next-line
              submitButton.value!.disabled = false;
            
          }else{
            if(data.message === "E-mail não verificado"){
              Swal.fire({
                title: 'Email não verificado!!',
                icon: "error",
                text: "Por favor verifique seu email, se você não recebeu o link ainda, clique no botão abaixo.",
                showDenyButton: false,
                confirmButtonText: 'Enviar novamente',
              }).then(async (result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                await ApiService.vueInstance.axios.post("email/verification", { email: values.email })
                Swal.fire({
                  title: 'Sucesso!',
                  icon: "success",
                  text: "Email de verificação enviado novamente!.",
                  confirmButtonText: 'OK',
                });
              }
            })
            }else{
           
            Swal.fire({
              title: "Oops...",
              text: data.message,
              icon: "error",
              
            });
            }
              //Deactivate indicator
              submitButton.value?.removeAttribute("data-kt-indicator");
              // eslint-disable-next-line
              submitButton.value!.disabled = false;
          }
          
        })
        .catch(({ response }) => {
       
          if(response && response.data.message){
           
           
            Swal.fire({
              title: "Oops...",
              text: response.data.message,
              icon: "error",
              
            });
        
          }else{
            Swal.fire({
              title: "Oops...",
              text: "Tivemos um problema para realizar o login, tente novamente mais tarde.",
              icon: "error",
            });
          }
          
         
              //Deactivate indicator
              submitButton.value?.removeAttribute("data-kt-indicator");
              // eslint-disable-next-line
              submitButton.value!.disabled = false;

        });
      }
     
     
    };

  

    const AuthProvider = (provider: string) => {
      router.push("/oauth/" + provider);
    };
    return {
      AuthProvider,
      onSubmitLogin,
      login,
      submitButton,
      currentLanguage
    };
  },
});
